:root {
  --primary-color: #2f313f;
  --default-color: #18a2d8;
  --info-color: #002dff;
  --secondary-color: #9010d5;
  --ternary-color: black;
  --primary-font: "Ubuntu", sans-serif;
  --body-color: #3f4258;
  --body-link-color: #3c3b69;
  --input-color: rgba(163, 162, 202, 0.45);
  --save-color: #2f313f;
  --border-color: #efeef7;
  --primary-bg-color: #fff;
  --doc-color: #2f313f;
  --secondary-bg-color: #f2f5ff;
  --default-bg-color: #f7f7f9;
  --default-border-color: #f1f0fd;
  --input-bg-color: #fff;
  --userInput-bg-color: #fcf6ff;
  --botAnswers-bg-color: #f6f7ff;
  --botActions-bg-color: #f5f5f5;
  --codeEditor-bg-color: #fafafa;
  --save-color: #2f313f;
  --toolTip-bg: #2f313f;
  --primary-btn-bg: #4bc0e5;
  --primary-btn-bg-hover: #25aad3;
  --scrollbar-thumb: rgb(218, 218, 218);
  --shadow: 0px 1px 2px #efeef6;
  --tr-hover: #f8fcff;
  --botCounter-bg: rgba(245, 245, 245, 1);
  --modal-bg: #fafafb;
  --next--prevs-bg: #f8f8f8;
  --modalContent-bg: #fafafb;
  --modalAction-bg: #d8d8d9;
  --inputRqnge-bg: #c5c5c5;
}

.dark {
  --primary-bg-color: #2f313f;
  --doc-color: #fff;
  --secondary-bg-color: #3c3f4c;
  --save-color: #4f515f;
  --default-bg-color: #494c5a;
  --input-bg-color: rgba(246, 247, 255, 0.07);
  --default-border-color: rgb(226 228 244 / 12%);
  --body-color: #a3a2ca;
  --ternary-color: #a3a2ca;
  --shadow: 0px 2px 3px rgba(25, 25, 50, 0.45);
  --userInput-bg-color: #3c3f4c;
  --botAnswers-bg-color: #3c3f4c;
  --botActions-bg-color: #3c3f4c;
  --codeEditor-bg-color: #212121;
  --border-color: #4f515f;
  --scrollbar-thumb: #4f515f;
  --tr-hover: #000000;
  --botCounter-bg: rgb(58 58 58);
  --toolTip-bg: #242424;
  --modal-bg: #343434;
  --next--prevs-bg: #3d3f4c;
  --modalContent-bg: #0000003b;
  --modalAction-bg: #56565a;
  --inputRqnge-bg: #2f313f;
}
.dark .layout .wrap-content {
  background-color: #4f515f;
}

/**
 *
 * General Styles
 *
 ***/
* {
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: var(--primary-font);
  color: var(--body-color);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #002060;
}

ul {
  list-style-type: none;
}

h3 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  color: #90a955;
  text-transform: uppercase;
}/*# sourceMappingURL=Style.css.map */